import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Post.css';
import { BiComment, BiDislike, BiLike } from 'react-icons/bi';

function Post() {
  const { postid } = useParams(); // Get postid from the URL
  const navigate = useNavigate();

  // State to store post data fetched from the API
  const [post, setPost] = useState({
    profileimage: '',
    time: '',
    caption: '',
    image: '',
    likes: 0,
    dislikes: 0,
    comments: 0,
  });

  // Fetch post details based on postid
  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await fetch('https://crowdbuzz.in/api/getPostDetails.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ postId: postid }) // Use the postid from the URL
        });
        
        const data = await response.json();
        
        if (data.status === 'success') {
          setPost({
            profileimage: data.post.emojiposturl,
            time: data.post.postCreatedDate,
            caption: data.post.postContent,
            image: data.post.postImage,
            likes: data.post.likes,
            dislikes: data.post.dislikes,
            comments: data.post.comments,
          });
        } else {
          console.error('Error fetching post:', data.message);
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    if (postid) {
      fetchPostDetails();
    }
  }, [postid]);

  // Handle post click to navigate to comments and homepage
  const handlePostClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.crowdbuzztechnologies.crowdbuzz&hl=en_IN';
  };

  return (
    <div className="Post" onClick={handlePostClick} style={{ marginBottom: '80px' }}>
      <div className="post-link">
        <div className="post-card">
          <div className="user-info">
            <div className="user-details">
              {/* <img src={post.profileimage} alt="Profile" /> */}
              <div>
                <h3>Unknown</h3>
                <div className="time">{post.time}</div>
              </div>
            </div>
            <div className="options-menu">
              <i className="fas fa-ellipsis-h"></i>
            </div>
          </div>
          <div className="post-text">
            {post.caption}
          </div>
          {post.image && <img src={`https://crowdbuzz.in/api/posts/${post.image}`} alt="Post" />} {/* Only render if image URL is valid */}
          <div className="post-actions" > {/* Add bottom margin */}
            <span>
              <BiLike className="action-icon" />
              {post.likes}
            </span>
            <span>
              <BiDislike className="action-icon" />
              {post.dislikes}
            </span>
            <span>
              <BiComment className="action-icon" />
              {post.comments}
            </span>
            <span>
              <i className="far fa-share-square"></i>
              Share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
