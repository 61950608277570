import React, { useState } from 'react';
import './Signup.css';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup } from '../components/firebase.js';
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer and toast

// Import the CSS for react-toastify
import 'react-toastify/dist/ReactToastify.css';

function Signup() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    retypePassword: ''
  });

  const navigate = useNavigate(); // For redirecting after signup

  const generateToken = () => uuidv4();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.retypePassword) {
      toast.error('Passwords do not match.'); // Show password mismatch toast
      return;
    }

    const userData = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      loginOptions: 'email',
      deviceToken: generateToken()
    };

    try {
      const response = await axios.post('https://crowdbuzz.in/api/saveUser.php', userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status === 'success') {
        toast.success('User created successfully!'); // Show success toast
        navigate('/'); // Redirect to homepage after successful signup
      } else if (response.data.message === 'User already exists') {
        toast.error('User already exists.'); // Show user already exists toast
        // Do not navigate away from the page
      } else {
        toast.error(response.data.message); // Show other error toast
      }
    } catch (error) {
      console.error('Error details:', error.response);
      toast.error('An error occurred while creating the account.'); // Show general error toast
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userData = {
        name: user.displayName,
        email: user.email,
        loginOptions: 'google',
        deviceToken: generateToken()
      };

      const response = await axios.post('https://crowdbuzz.in/api/saveUser.php', userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status === 'success') {
        toast.success('Google login successful!'); // Show success toast
        navigate('/'); // Redirect to homepage after Google login
      } else {
        toast.error(response.data.message); // Show error toast
      }
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      toast.error('An error occurred during Google login.'); // Show error toast
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              className="form-control" 
              placeholder="Enter your name" 
              value={formData.name} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              className="form-control" 
              placeholder="Enter your email" 
              value={formData.email} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              className="form-control" 
              placeholder="Enter your password" 
              value={formData.password} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="retype-password">Retype Password</label>
            <input 
              type="password" 
              id="retype-password" 
              name="retypePassword" 
              className="form-control" 
              placeholder="Retype your password" 
              value={formData.retypePassword} 
              onChange={handleInputChange} 
              required 
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
        </form>
        <p className="terms-text">
          By signing up, you accept our <Link to="/terms-and-conditions">Terms & Conditions</Link>.
        </p>
        <hr />
        <button className="btn btn-google btn-block" onClick={handleGoogleSignIn}>
          <FcGoogle size={24} /> Continue with Google
        </button>
        <div className="login-link">
          <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
      </div>
      <ToastContainer autoClose={4000} position="top-center" /> {/* Toast container */}
    </div>
  );
}

export default Signup;
