import React, { useState, useEffect } from 'react';
import './Home.css';
import Post from '../postComponents/Post';
import axios from 'axios'; // Import axios for API requests
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify

function Home() {
  const [posts, setPosts] = useState([]); // State to store posts
  const [loading, setLoading] = useState(true); // State to handle loading state

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Make API request to get posts and user interactions
        const response = await axios.post('https://crowdbuzz.in/api/getAllPosts.php', {
          userId: "12884" // Replace with actual user ID
        });

        if (response.data.status === 'success') {
          // Store fetched posts into state
          setPosts(response.data.posts);
        } else {
          // Show API error message using toast notification
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error details:', error.response);
        // Show general error message using toast notification
        toast.error('An error occurred while fetching posts.');
      } finally {
        // End loading state
        setLoading(false);
      }
    };

    // Fetch posts when component mounts
    fetchPosts();
  }, []);

  return (
    <div className="home-container">
      <div className="home-top-nav">
        <span className="home-nav-title">Home</span>
      </div>
      <div className="home-content">
        {loading ? (
          <p>Loading...</p> // Show loading message while fetching
        ) : posts.length > 0 ? (
          posts.map((post, index) => (
            <Post
              key={index}
              profileimage={post.profileimage}
              time={post.time}
              caption={post.caption}
              image={post.image}
              likes={post.likes}
              dislikes={post.dislikes}
              comments={post.comments}
              postliked={post.postliked} // Pass postliked from API
              postunliked={post.postunliked} // Pass postunliked from API
            />
          ))
        ) : (
          <p>No posts available.</p> // Show message if no posts are found
        )}
      </div>
      <ToastContainer autoClose={4000} position="top-center" /> {/* Toast container */}
    </div>
  );
}

export default Home;
