// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDXbYGFE1IVPIx0NzlV-Glat1cSVmSeMMs",
//   authDomain: "cosmectsecretbase.firebaseapp.com",
//   projectId: "cosmectsecretbase",
//   storageBucket: "cosmectsecretbase.appspot.com",
//   messagingSenderId: "673902025659",
//   appId: "1:673902025659:web:736e8d3cf47ad45e55829e",
//   measurementId: "G-G1BFBGME3Z"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);




// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXbYGFE1IVPIx0NzlV-Glat1cSVmSeMMs",
  authDomain: "cosmectsecretbase.firebaseapp.com",
  projectId: "cosmectsecretbase",
  storageBucket: "cosmectsecretbase.appspot.com",
  messagingSenderId: "673902025659",
  appId: "1:673902025659:web:736e8d3cf47ad45e55829e",
  measurementId: "G-G1BFBGME3Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and Google provider
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup };
